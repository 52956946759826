<template>
  <section class="auth">
    <div class="portrait">
      <div class="auth__logotype">
        <h1 class="header__title">Музыкальный <br>эрудит</h1>
      </div>

      <div class="auth__container" v-if="showPage === 'main'">
        <h2 class="auth__title">Авторизуйтесь</h2>
        <div class="buttons">
          <button class="btn" @click="authDemoUser">Начать без регистрации</button>
          <button class="btn" @click="showLoginPage">Вход</button>
        </div>
      </div>

      <div class="auth__container" v-else-if="showPage === 'login'">
        <h2 class="auth__title">Авторизуйтесь</h2>
        <div class="inputs">
          <div class="input__block" v-for="input of auth_inputs" :key="input.id">
            <input :placeholder="input.name" :type="input.type" v-model="authOtp.phone" v-if="input.mask"
              v-mask="input.mask" />
            <input v-else :type="input.type" @keyup="checkMaxLength(input)" v-model="authOtp.phone" />
            <div class="input__agreement" v-if="authOtp.isShowAgreement">
              <input v-model="authOtp.agreement" id="agreement-personal" type="checkbox"/>
              <label for="agreement-personal">
                Согласен с обработкой персональных данных
              </label>
            </div>
            <div class="policy">
              <p class="policy__text">Продолжая использовать наш сайт, вы даете согласие на обработку файлов Cookies и других пользовательских данных, в соответствии с
                <a class="policy__link"  :href="pdfFilePath" target="_blank">Политикой защиты и обработки персональных данных</a></p>
            </div>
          </div>
        </div>
        <br>
        <button class="btn" @click="getOTP" :disabled="!authOtp.agreement || authOtp.phone.length < 16">Получить код</button>
      </div>

      <div class="auth__container" v-else-if="showPage === 'otp'">
        <h2 class="auth__title">Авторизуйтесь</h2>
        <div class="inputs">
          <div class="input__block" v-for="input of otp_inputs" :key="input.id">
            <input :placeholder="input.name" :type="input.type" v-model="authOtp.code" v-if="input.mask"
              v-mask="input.mask" />
            <input v-else :type="input.type" @keyup="checkMaxLength(5)" v-model="authOtp.code" />
          </div>
        </div>
        <a class="otp__re-request" @click="showLoginPage">Получить код повторно</a>
        <button class="btn" @click="verifyOtp" :disabled="authOtp.code.length !=6">Войти</button>
      </div>

      <div class="auth__container" v-else>
        <AppPreloader/>
      </div>
    </div>
  </section>
</template>


<script>
import axios from 'axios';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import AppPreloader from '@/components/game/Preloader.vue';
import policy from '@/assets/politika_konfiden.pdf'
export default {
  name: "AuthMain",

  components: {
    AppPreloader,
  },

  data: () => ({
    isGuest: false,
    showPage: 'main',
    auth: {
      login: '',
      password: '',
    },
    authOtp: {
      phone: '',
      code: '',
      otpId: '',
      isShowAgreement: true,
      agreement: false,
    },
    register: {
      login: '',
      role: 3,
      consent: true,
    },
    auth_inputs: [{ id: "login", name: "Номер телефона", type: "phone", mask: "+7(###)###-##-##" }],
    otp_inputs: [{ id: "code", name: "Введите код из SMS", type: "text", mask: "######" }],
    pdfFilePath: policy
  }),

  computed: {
    ...mapGetters([
      'orientation',
    ]),
  },

  mounted() {
    setTimeout(() => {
      if (!window.location.pathname.startsWith('/auth')) return;
      this.$metrika.add(this.$metrika.consts.AUTH_VIEW);
    }, 100);

    if(this.$cookies.get('demo-mode-used')) {
      this.showLoginPage();
      this.checkLoginByToken();
    }
  },

  methods: {
    ...mapMutations(['setUserRole', 'clearGameModule']),
    ...mapActions(['createToken', 'loginByToken']),
    checkMaxLength(input) {
      if (this.register[input.id].length < this.max) return;
      this.$bus.$emit('showNotification', { 
        message: `Максимальное количество символов: ${this.max}!`,
        status: 'danger-game', 
      });
    },

    showOtpVerifyPage() {
      this.showPage = 'otp';
    },
    showLoginPage() {
      this.showPage = 'login';
    },

    loginByOtp() {
      return new Promise(async resolve => {
        try {
          const options = { ...this.$router.currentRoute.query, otp: this.authOtp };
          const response = await axios.post(`/api/auth/login/otp`, options);
          this.$metrika.add(this.$metrika.consts.AUTH_OTP_SUCCESS);
          this.$cookies.remove('demo-mode-used');
          this.createToken(response.data);
          this.$router.push('/');
          resolve(true);
        } catch (err) {
          this.$metrika.add(this.$metrika.consts.AUTH_OTP_ERROR);
          this.$bus.$emit('showNotification', {
            message: err.response.data,
            status: 'danger-game',
          });
        };
      });
    },

    checkLoginByToken() {
      return new Promise(async resolve => {
        try {
          const url = new URL(window.location.href);
          const keys = ['success', 'expire_at', 'payload', 'sign', 'msisdn'];
          const values = keys.map(p => url.searchParams.get(p));
          if (!values.every(Boolean)) return;
          this.showPage = 'loading';
          await this.loginByToken(Object.fromEntries(_.zip(keys, values)));
          this.$metrika.add(this.$metrika.consts.AUTH_TOKEN_SUCCESS);
          this.$cookies.remove('demo-mode-used');
          this.$router.push('/');
        } catch (err) {
          this.$metrika.add(this.$metrika.consts.AUTH_TOKEN_ERROR);
          this.$bus.$emit('showNotification', {
            message: err.response.data,
            status: 'danger-game',
          });
        } finally {
          this.showPage = 'login';
          resolve(true);
        };
      });
    },

    async getOTP() {
      try {
        if (!_.size(this.authOtp.phone)) {
          return this.$bus.$emit('showNotification', { 
            status: 'danger-game', 
            message: 'Введите номер телефона',
          });
        } else if (this.authOtp.phone.length < 16 && this.authOtp.phone.length > 0) {
          return this.$bus.$emit('showNotification', { 
            status: 'danger-game', 
            message: 'Введен некорректный номер телефона',
          });
        };
        await this.checkUserExist(this.authOtp.phone);
        const msisdn = this.authOtp.phone.replace(/[\+\(\)\-\s]+/g, '');
        await this.generateOtp(msisdn);
        this.showOtpVerifyPage();
      } catch(err) {
        this.$bus.$emit('showNotification', { 
          message: err.response.data,
          status: 'danger-game', 
        });
      };
    },
    checkUserExist(phone) {
      return new Promise(async (resolve, reject) => {
        try {
          const agreement = this.authOtp.agreement;
          try {
            this.$metrika.add(this.$metrika.consts.AUTH_CHECK_USER_EXIST);
            const response = await axios.get(`/api/otp/user/${phone}`);
            if (response.data.agreement) {
              this.authOtp.isShowAgreement = false;
              this.authOtp.agreement = true;
            };
            resolve(true);
          } catch (err) {
            this.$metrika.add(this.$metrika.consts.AUTH_REGISTER_USER);
            this.register.login = phone;
            const payload = { ...this.register, ...this.$router.currentRoute.query };
            const response = await axios.post('/api/user/gamer/otp', payload);
            resolve(response);
          };
        } catch (message) {
          this.$bus.$emit('showNotification', { status: 'danger-game', message });
          console.error(message);
          resolve(message);
        };
      });
    },
    async authDemoUser() {
      const response = await axios.post('/api/auth/login/demo');
      this.$store.dispatch("createToken", response.data);
      this.$cookies.set('demo-mode-used', true, "1d");
      this.$router.push('/demo');
    },
    generateOtp(msisdn) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await axios.get(`/api/otp/generate/${msisdn}`);
          this.authOtp.id = response.data;
          resolve(true);
        } catch (err) {
          reject(err);
        };
      });
    },
    verifyOtp() {
      if (!this.authOtp.agreement) {
        return this.$bus.$emit('showNotification', { 
          status: 'danger-game', 
          message: 'Требуется согласие на обработку персональных данных',
        });
      };
      return new Promise(async resolve => {
        try {
          const validOtp = /\d{6}/g.test(this.authOtp.code);
          if (!validOtp) throw { response: { data: 'Введите код из 6 цифр' } };
          await axios.get(`/api/otp/verify/${this.authOtp.code}/${this.authOtp.id}`);
          this.clearGameModule();
          this.setUserRole(0);
          resolve(this.loginByOtp());
        } catch(err) {
          this.$bus.$emit('showNotification', { 
            message: err.response.data,
            status: 'danger-game', 
          });
        };
      });
    }
  }

};
</script>

<style lang="scss" scoped>
.policy{
  border-radius: 10px;
  background-color: rgba(239, 240, 247, .6);
  margin-top: 10px;
}
.policy__text{
  padding: 15px;
  color: #3d3a3a;
  font-size: 10px;
  line-height: 1.2;
}
.policy__link{
  text-decoration: none;
  color: #3d3a3a;
  font-weight: bold;
}
.policy__link:hover{
  border-bottom: 1px solid #3d3a3a;
}

.buttons{
  display: flex;
  flex-direction: column;
  button{
    margin-top: 20px;
  }
}

.input__consent::before {
  border-color: #fff !important;
}

.btn[disabled] {
  opacity: .5;
}

.input__agreement {
  display: grid;
  grid-template-columns: calc(1em + 30px) 1fr;
  background: #EFF0F7;
  border-radius: 10px;
  font-family: "Roboto", sans-serif;
  color: #3d3a3a;
  align-items: center;
  margin-top: 10px;
  font-size: 10px;
  font-weight: 500;
  input[type="checkbox"] {
    height: calc(1em + 30px);
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
      top: calc(100% - 28px);
      left: calc(100% - 22px);
    }
    &::after {
      content: "";
      width: 16px;
      height: 16px;
      position: absolute;
      left: 12px;
      top: 12px;
      border-radius: 2px;
      border: 1px solid;
    }
  }
}

.header__title {
  color: #14142B;
  font-size: 28px;
  line-height: 40px;
  text-align: center;
  font-weight: 700;
  text-transform: none;
  word-wrap: break-word;
  padding: 40px 0 25px 0;
}

.auth__title {
  text-align: center;
  padding: 0 0 25px 0;
  line-height: 25px;
  font-size: 15px;
  color: #14142B;
}

.otp__re-request {
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */
  text-align: center;
  letter-spacing: 0.75px;
  text-decoration-line: underline;
  color: #14142B;
  display: block;
  margin-bottom: 20px;
}

.auth {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 35px;

  .portrait {
    padding-bottom: 30px;
  }

  .landscape {
    margin-top: 5%;
    height: 100%;

    .btn {
      height: 39px !important;
    }

    input {
      padding: 10px 15px !important;
    }


    .auth_left {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 45%;
      margin-right: 5%;

      // .auth__logotype {
      //   flex-basis: 0;
      //   margin-top: 0;
      //   font-size: 48px;
      // }
    }

    .auth_right {
      width: 50%;
      display: flex;
      align-items: center;

      .auth__container {
        padding-top: 0;

      }
    }
  }

  &__logotype {
    flex-basis: 125px;
    text-align: center;
    margin-top: 65px;
    font-size: 52px;
    text-transform: uppercase;
  }

  &__container {
    padding-top: 0;
    flex-grow: 1;

    .title {
      font-size: 20px;
      margin-bottom: 10px;
    }

    .inputs {
      margin-bottom: 10px;
    }

    .reset {
      text-align: center;
      margin-top: 20px;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
    }
  }

  .footer {
    cursor: pointer;
    text-align: center;
    margin-top: 10px;
  }
}
</style>